var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-box building"},[_c('div',{staticClass:"login-main"},[_c('a-form',{staticClass:"login-form login-form-box",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"login-input-item"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'userName',
          { rules: [{ required: true, message: '请输入账号!' }] } ]),expression:"[\r\n          'userName',\r\n          { rules: [{ required: true, message: '请输入账号!' }] },\r\n        ]"}],staticClass:"mobile",attrs:{"placeholder":"请输入账号"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user","autocomplete":"off"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"login-input-item"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: true, message: '请输入正确的密码!' }] } ]),expression:"[\r\n          'password',\r\n          { rules: [{ required: true, message: '请输入正确的密码!' }] },\r\n        ]"}],staticClass:"mobile",attrs:{"autocomplete":"off","type":"password","placeholder":"请输入密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"verify-input-item"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'verification_code',
          { rules: [{ required: true, message: '请输入验证码' }] } ]),expression:"[\r\n          'verification_code',\r\n          { rules: [{ required: true, message: '请输入验证码' }] },\r\n        ]"}],staticClass:"VerificationCode",attrs:{"autocomplete":"off","placeholder":"请输入验证码"}}),_c('img',{staticStyle:{"width":"100%","position":"absolute","left":"182px"},attrs:{"src":_vm.VerificationCodeSrc},on:{"click":_vm.refreshCheckCode}})],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remember',
          {
            valuePropName: 'checked',
            initialValue: true,
          } ]),expression:"[\r\n          'remember',\r\n          {\r\n            valuePropName: 'checked',\r\n            initialValue: true,\r\n          },\r\n        ]"}]},[_vm._v(" 记住登录状态 ")]),_c('a',{staticClass:"login-form-forgot",staticStyle:{"display":"none"},attrs:{"href":""}},[_vm._v(" 忘记密码 ")]),_c('div',{staticClass:"mt32 login-btn"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 登录 ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }